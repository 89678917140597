import React, { useEffect, useState } from "react";

import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getMatkaMarketsAPI } from "../../service/matka/matka.service";

export default function MatkaInplay({ completeGame }) {
  const [matches, setMatches] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const userData = useSelector((state) => state.account.userData);
  const getAllMatches = async () => {
    const { response } = await getMatkaMarketsAPI(
      completeGame ? true : false
    );
    setMatches(response);
  };
  const navigate = useNavigate();
  useEffect(() => {
    getAllMatches();
  }, [currentPage, completeGame]);

  return (
    <>
      <div
        className="content-wrapper"
        style={{
          minHeight: "1263.44px",
        }}
      >
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1> Matka {completeGame ? "Complete" : "Inplay"}</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>
                  <li className="breadcrumb-item active" />
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                {/* /.card */}
                <div className="card">
                  <form action="#" method="post" id="demoForm">
                    <div className="card-header">
                      <h2 className="card-title">Matka List</h2>
                    </div>
                    {/* /.card-header */}
                    <div className="card-body">
                      <table
                        id="example1"
                        className="table table-bordered table-striped "
                      >
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>SNo</th>
                            <th>Code</th>
                            <th>Name</th>
                            <th>Open Time</th>
                            <th>Close Time</th>
                            <th>Result Time</th>
                            {completeGame ? <th>Result</th> : null}
                          </tr>
                        </thead>
                        <tbody>
                          {matches.map((item, index) => (
                            <tr key={index}>
                              <td>
                                <div align="center">
                                  <div className="btn-group">
                                    <button
                                      type="button"
                                      className={
                                        completeGame
                                          ? "btn btn-sm  btn-primary dropdown-toggle dropdown-hover dropdown-icon"
                                          : "btn  btn-outline-primary dropdown-toggle dropdown-hover dropdown-icon"
                                      }
                                      data-toggle="dropdown"
                                    >
                                      <span className="sr-only">
                                        Toggle Dropdown
                                      </span>
                                    </button>
                                    <div
                                      className="dropdown-menu"
                                      role="menu"
                                    >
                                      {!completeGame && (
                                        <a
                                          className="dropdown-item btn"
                                          onClick={() => {
                                            navigate(
                                              `/matka/position/${item?.id}`,
                                              {
                                                state: {
                                                  match: item,
                                                },
                                              }
                                            );
                                          }}
                                        >
                                          Matka Position
                                        </a>
                                      )}
                                      <a
                                        className="dropdown-item btn"
                                        onClick={() => {
                                          navigate(
                                            `/matka/casino-select-plus-minus-report/${
                                              item?.id
                                            }`,
                                            {
                                              state: {
                                                match: item,
                                              },
                                            }
                                          );
                                        }}
                                      >
                                        Matka plus minus
                                      </a>
                                      <a
                                        className="dropdown-item btn"
                                        onClick={() => {
                                          navigate(
                                            `/matka/bets/${item?.id}`,
                                            {
                                              state: {
                                                match: item,
                                              },
                                            }
                                          );
                                        }}
                                      >
                                        Matka bets
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td>{index + 1}</td>
                              <td>{item?.id}</td>
                              <td>{item?.matka?.name}</td>
                              <td>
                                <i className="fa fa-clock-o" />
                                &nbsp; {item?.matka?.openTime}
                              </td>

                              <td>
                                <i className="fa fa-clock-o" />
                                &nbsp; {item?.matka?.closeTime}
                              </td>

                              <td>
                                <i className="fa fa-clock-o" />
                                &nbsp; {item?.matka?.resultTime}
                              </td>
                              {completeGame ? (
                                <td>
                                  {item?.result
                                    ? item?.result
                                    : "Not Declared"}
                                </td>
                              ) : null}
                            </tr>
                          ))}
                        </tbody>
                        <tfoot />
                      </table>
                      <ul className="pagination pagination-sm  mt-3 m-0 float-right">
                        <div>
                          <li className="page-item">
                            <a
                              className="page-link"
                              onClick={() => {
                                if (currentPage == 1) return;
                                setCurrentPage(currentPage - 1);
                              }}
                            >
                              «
                            </a>
                          </li>
                        </div>
                        <li className="page-item active">
                          <a className="page-link" href="/client/0/?by=#">
                            {currentPage}
                          </a>
                        </li>
                        <div>
                          <li className="page-item">
                            <a
                              className="page-link"
                              onClick={() => {
                                setCurrentPage(currentPage + 1);
                              }}
                            >
                              »
                            </a>
                          </li>
                        </div>
                      </ul>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
