import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import {
  addNewClientAPI,
  getGeneratedUsernameAPI,
  getUserDataAPI,
  getChildUserInfoAPI,
  ChangeClientStatusAPI,
  getUsersWithUserTypeAPI,
} from "../service/UserService";
import { EditClientAPI } from "../service/UserService";
import { Encrypt } from "../utils/constants";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import ReactSelect from "react-select";

export default function EditClient() {
  const [clientData, setClientData] = useState({});
  const location = useLocation();
  const [globalSetting, setGlobalSetting] = useState({});
  const [payload, setPayload] = useState({});
  const [isAdmin, setIsAdmin] = useState(false);
  const userData = useSelector((state) => state.account.userData);
  const userType = useParams()?.userType;
  const [userId, setUserId] = useState(location.state?.client?.uid);
  const [isCommission, setIsCommission] = useState(true);
  const [userTypeList, setUserTypeList] = useState();
  const [isCasinoCommission, setIsCasinoCommission] = useState("yes");
  const [isMatkaCommission, setIsMatkaCommission] = useState("yes");
  const [selectedParent, setSelectedParent] = useState({
    value: location.state?.client?.parentUserId,
    label: location.state?.client?.parentUserName,
  });
  const getUserInfo = async () => {
    const { response } = await getUserDataAPI();
    setIsAdmin(false);

    setClientData(response);
  };

  const getChildUserInfo = async () => {
    const { response } = await getChildUserInfoAPI(userId);

    setClientData((prevState) => ({
      ...prevState,
      ...response?.childInfo,
    }));
    setPayload((prevState) => ({
      ...prevState,
      ...response?.childInfo,
    }));
    setIsCommission(
      response?.childInfo?.sessionCommission != "0" &&
        response?.childInfo?.matchCommission != "0"
        ? "true"
        : "false"
    );
    setIsCasinoCommission(
      response?.childInfo?.casinoCommission != "0" ? "yes" : "false"
    );
    setIsMatkaCommission(
      response?.childInfo?.matkaCommission != "0" ? "yes" : "false"
    );
  };

  useEffect(() => {
    if (userId) {
      getChildUserInfo();
    } else {
      getUserInfo();
    }
  }, [userId]);

  const handleSubmit = async () => {
    let filteredPayload = {
      ...payload,
    };
    Object.keys(filteredPayload).forEach(
      (key) =>
        (filteredPayload[key] === location?.state?.client[key] ||
          filteredPayload[key] === clientData[key]) &&
        delete filteredPayload[key]
    );
    try {
      const { response, code, error, status } = await EditClientAPI(
        filteredPayload,
        userId
      );

      if (code === 200) {
        toast.success(response?.status || "User Update Successfully");
        setTimeout(() => {
          window.location.href = `/${userType}`;
        }, 500);
        setPayload({});
      } else if (code === 500) {
        toast.error(response);
      } else if (code !== 200 && status) {
        toast.error(
          status || "Something went wrong Please check all you field"
        );
        setPayload({
          ...payload,
          password: "",
        });
      } else {
        toast.error(
          response || "Something went wrong Please check all you field"
        );
        setPayload({
          ...payload,
          password: "",
        });
      }
    } catch (err) {
      setPayload({});
      toast.error(
        err.message || "Something went wrong Please check all you field"
      );
    }
  };
  const handleCasinoChange = async (e, matka = false) => {
  };
  const handleCommissionChange = (e) => {
    const commissionOn = e.target.value === "false";
    if (commissionOn) {
      setPayload({
        ...payload,
        matchCommission: 0,
        sessionCommission: 0,
        casinoCommission: 0,
        matkaCommission: 0,
      });
    }
  };
  useEffect(() => {
    setClientData({
      partnershipAndCommission: {
        matchCommission: 0,
        sessionCommission: 0,
        casinoCommission: 0,
        matkaCommission: 0,
      },
    });
  }, []);

  useEffect(() => {
    setPayload(location?.state?.client);
  }, []);

  const generatePassword = () => {
    const passwordLength = 6;
    let newPassword = "";
    for (let i = 0; i < passwordLength; i++) {
      newPassword += Math.floor(Math.random() * 10); // Generate a random number between 0 and 9
    }
    setPayload({
      ...payload,
      password: newPassword,
    });
  };

  return (
    <div _ngcontent-ng-c3705319800="" className="main_content">
      <router-outlet _ngcontent-ng-c3705319800="" />
      <app-create-user _nghost-ng-c420556888="" className="ng-star-inserted">
        <div _ngcontent-ng-c420556888="" className="page_header">
          <h1 _ngcontent-ng-c420556888="" className="page_heading">
            Create New {userType}
          </h1>
          <div _ngcontent-ng-c420556888="" className="breadcrumb_block">
            <nav _ngcontent-ng-c420556888="" aria-label="breadcrumb">
              <ol _ngcontent-ng-c420556888="" className="breadcrumb">
                <li _ngcontent-ng-c420556888="" className="breadcrumb-item">
                  <a _ngcontent-ng-c420556888="" href="#/dashboard">
                    Home
                  </a>
                </li>
                <li
                  _ngcontent-ng-c420556888=""
                  aria-current="page"
                  className="breadcrumb-item active"
                >
                  Create New {userType}
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div
          _ngcontent-ng-c420556888=""
          className="Super_master_details_section"
        >
          <div _ngcontent-ng-c420556888="" className="jmd_input">
            <form
              _ngcontent-ng-c420556888=""
              noValidate=""
              className="ng-untouched ng-pristine ng-invalid"
            >
              <div _ngcontent-ng-c420556888="" className="row">
                <div _ngcontent-ng-c420556888="" className="col-md-3 col-6">
                  <div _ngcontent-ng-c420556888="" className="clearfix_input">
                    <label _ngcontent-ng-c420556888="" htmlFor="">
                      Name
                    </label>
                    <input
                      _ngcontent-ng-c420556888=""
                      formcontrolname="name"
                      type="text"
                      value={payload?.name}
                      onChange={(e) =>
                        setPayload({
                          ...payload,
                          name: e.target.value,
                        })
                      }
                      id="name"
                      name="name"
                      className="form-control ng-untouched ng-pristine ng-invalid"
                    />
                  </div>
                </div>
                <div _ngcontent-ng-c420556888="" className="col-md-3 col-6">
                  <div _ngcontent-ng-c420556888="" className="clearfix_input">
                    <label _ngcontent-ng-c420556888="" htmlFor="">
                      Reference
                    </label>
                    <input
                      _ngcontent-ng-c420556888=""
                      type="text"
                      formcontrolname="reference"
                      id="name"
                      name="name"
                      value={payload?.reference}
                      onChange={(e) =>
                        setPayload({
                          ...payload,
                          reference: e.target.value,
                        })
                      }
                      className="form-control ng-untouched ng-pristine ng-valid"
                    />
                  </div>
                </div>
              </div>
              {/*  <div _ngcontent-ng-c420556888="" className="row">
                <h6
                  _ngcontent-ng-c420556888=""
                  className="input-sub-headding"
                >
                  My Limit
                </h6>
                <div _ngcontent-ng-c420556888="" className="col-md-3">
                  <div _ngcontent-ng-c420556888="" className="clearfix_input">
                    <label _ngcontent-ng-c420556888="" htmlFor="">
                      Rem Limit
                    </label>
                    <input
                      _ngcontent-ng-c420556888=""
                      readOnly=""
                      type="text"
                      placeholder={0}
                      formcontrolname="remainingLimit"
                      id="name"
                      name="name"
                      disabled
                      value={
                        (userData?.userType === "SUPER_ADMIN" &&
                          userType?.toUpperCase() === "ADMIN") ||
                        (userData?.userType === "ADMIN" &&
                          userType?.toUpperCase() === "SUB_ADMIN") ||
                        (userData?.userType === "SUB_ADMIN" &&
                          userType?.toUpperCase() === "MASTER") ||
                        (userData?.userType === "MASTER" &&
                          userType?.toUpperCase() === "SUPER_AGENT") ||
                        (userData?.userType === "SUPER_AGENT" &&
                          userType?.toUpperCase() === "AGENT") ||
                        (userData?.userType === "AGENT" &&
                          userType.toUpperCase() === "CLIENT")
                          ? userData?.balance - payload?.balance
                          : clientData?.partnershipAndCommission?.balance -
                            payload?.balance
                      }
                      className="form-control ng-untouched ng-pristine ng-valid"
                    />
                  </div>
                </div>
              </div> */}
              {selectedParent && userType?.toLowerCase() != "client" && (
                <div
                  _ngcontent-ng-c420556888=""
                  className="row ng-star-inserted"
                >
                  <div _ngcontent-ng-c420556888="" className="col-md-3 col-6">
                    <h6
                      _ngcontent-ng-c420556888=""
                      className="input-sub-headding"
                    >
                      {userType} Share{" "}
                    </h6>
                    <div
                      _ngcontent-ng-c420556888=""
                      className="clearfix_input"
                    >
                      <label _ngcontent-ng-c420556888="" htmlFor="">
                        Share %
                      </label>
                      <input
                        _ngcontent-ng-c420556888=""
                        id="name"
                        name="name"
                        max={
                          userType == "admin"
                            ? clientData?.parentPartnership
                            : clientData?.myPartnership
                        }
                        defaultValue={0}
                        min={0.0}
                        type="number"
                        value={payload?.myPartnership}
                        onChange={(e) =>
                          setPayload({
                            ...payload,
                            myPartnership: e.target.value,
                          })
                        }
                        readOnly=""
                        className="form-control ng-star-inserted"
                      />
                    </div>
                  </div>
                  <div _ngcontent-ng-c420556888="" className="col-md-3 col-6">
                    <h6
                      _ngcontent-ng-c420556888=""
                      className="input-sub-headding"
                    >
                      My Share
                    </h6>
                    <div
                      _ngcontent-ng-c420556888=""
                      className="clearfix_input"
                    >
                      <label _ngcontent-ng-c420556888="" htmlFor="">
                        Share %
                      </label>
                      <input
                        _ngcontent-ng-c420556888=""
                        readOnly=""
                        placeholder={0}
                        value={clientData?.parentPartnership}
                        disabled
                        formcontrolname="parrent_partnership"
                        type="text"
                        id="name"
                        name="name"
                        className="form-control ng-untouched ng-pristine ng-valid"
                      />
                    </div>
                  </div>
                </div>
              )}
              
              <div _ngcontent-ng-c420556888="" className="row">
                <h6
                  _ngcontent-ng-c420556888=""
                  className="input-sub-headding"
                >
                  {userType} Commission
                </h6>
                <div _ngcontent-ng-c420556888="" className="col-md-3">
                  <div _ngcontent-ng-c420556888="" className="clearfix_input">
                    <label _ngcontent-ng-c420556888="" htmlFor="">
                      Session Commission Type
                    </label>
                    <select
                      _ngcontent-ng-c420556888=""
                      aria-label="Default select example"
                      onChange={(e) => {
                        handleCommissionChange(e);
                        setIsCommission(e.target.value);
                      }}
                      value={isCommission}
                      formcontrolname="session_commission_type"
                      className="form-select form-control ng-untouched ng-pristine ng-invalid"
                    >
                      <option
                        _ngcontent-ng-c420556888=""
                        value="false"
                        selected=""
                      >
                        Commission Type
                      </option>
                      <option value="false" _ngcontent-ng-c420556888="">
                        No Comm
                      </option>
                      <option value="true" _ngcontent-ng-c420556888="">
                        Bet By Bet
                      </option>
                    </select>
                  </div>
                </div>
                <div _ngcontent-ng-c420556888="" className="col-md-3 col-6">
                  <div _ngcontent-ng-c420556888="" className="clearfix_input">
                    <label _ngcontent-ng-c420556888="" htmlFor="">
                      Match Commission{" "}
                    </label>
                    <input
                      max={
                        clientData?.partnershipAndCommission?.matchCommission
                      }
                      min={0.0}
                      defaultValue={0}
                      _ngcontent-ng-c420556888=""
                      formcontrolname="match_commission"
                      placeholder={0}
                      type="text"
                      disabled={isCommission === "false"}
                      id="name"
                      value={payload?.matchCommission}
                      onChange={(e) =>
                        setPayload({
                          ...payload,
                          matchCommission: e.target.value,
                        })
                      }
                      name="name"
                      className="form-control disabled ng-untouched ng-pristine ng-invalid"
                    />
                  </div>
                </div>
                <div _ngcontent-ng-c420556888="" className="col-md-3 col-6">
                  <div _ngcontent-ng-c420556888="" className="clearfix_input">
                    <label _ngcontent-ng-c420556888="" htmlFor="">
                      Session Commission
                    </label>
                    <input
                      _ngcontent-ng-c420556888=""
                      type="text"
                      placeholder={0}
                      disabled={isCommission === "false"}
                      max={
                        clientData?.partnershipAndCommission
                          ?.sessionCommission
                      }
                      min={0.0}
                      defaultValue={0}
                      value={payload?.sessionCommission}
                      onChange={(e) =>
                        setPayload({
                          ...payload,
                          sessionCommission: e.target.value,
                        })
                      }
                      formcontrolname="session_commission"
                      id="name"
                      name="name"
                      className="form-control disabled ng-untouched ng-pristine ng-invalid"
                    />
                  </div>
                </div>
              </div>
              {userType != "client" && (
                <div _ngcontent-ng-c420556888="" className="row">
                 

                 

                  {userType != "client" && (
                    <>
                      <div
                        _ngcontent-ng-c420556888=""
                        className="col-md-3 col-6"
                      >
                        <div
                          _ngcontent-ng-c420556888=""
                          className="clearfix_input"
                        >
                          <label _ngcontent-ng-c420556888="" htmlFor="">
                            Casino Partnership{" "}
                          </label>
                          <input
                            type="number"
                            max={
                              clientData?.partnershipAndCommission
                                ?.myCasinoPartnership
                            }
                            min={0.0}
                            defaultValue={0}
                            id="casino_commission"
                            required=""
                            value={payload?.myCasinoPartnership}
                            name="casinoCommission"
                            onChange={(e) =>
                              setPayload({
                                ...payload,
                                myCasinoPartnership: e.target.value,
                              })
                            }
                            className="form-control disabled ng-untouched ng-pristine ng-invalid"
                          />
                        </div>
                      </div>
                      <div
                        _ngcontent-ng-c420556888=""
                        className="col-md-3 col-6"
                      >
                        <div
                          _ngcontent-ng-c420556888=""
                          className="clearfix_input"
                        >
                          <label _ngcontent-ng-c420556888="" htmlFor="">
                            My Casino Share
                          </label>
                          <input
                            type="number"
                            value={
                              clientData
                                ?.parentCasinoPartnership
                            }
                            readOnly
                            className="form-control disabled ng-untouched ng-pristine ng-invalid"
                          />
                        </div>
                      </div>
                      <div
                        _ngcontent-ng-c420556888=""
                        className="col-md-3 col-6"
                      >
                        <div
                          _ngcontent-ng-c420556888=""
                          className="clearfix_input"
                        >
                          <label _ngcontent-ng-c420556888="" htmlFor="">
                            Matka Partnership{" "}
                          </label>
                          <input
                            type="number"
                            max={
                              clientData?.partnershipAndCommission
                                ?.myMatkaPartnership
                            }
                            min={0.0}
                            defaultValue={0}
                            id="casino_commission"
                            required=""
                            value={payload?.myMatkaPartnership}
                            name="casinoCommission"
                            onChange={(e) =>
                              setPayload({
                                ...payload,
                                myMatkaPartnership: e.target.value,
                              })
                            }
                            className="form-control disabled ng-untouched ng-pristine ng-invalid"
                          />
                        </div>
                      </div>
                      <div
                        _ngcontent-ng-c420556888=""
                        className="col-md-3 col-6"
                      >
                        <div
                          _ngcontent-ng-c420556888=""
                          className="clearfix_input"
                        >
                          <label _ngcontent-ng-c420556888="" htmlFor="">
                            My Matka Share
                          </label>
                          <input
                            type="number"
                            value={
                              clientData?.parentMatkaPartnership
                            }
                            readOnly
                            className="form-control disabled ng-untouched ng-pristine ng-invalid"
                          />
                        </div>
                      </div>
                    </>
                    
                  )}
                </div>
              )}
              <div _ngcontent-ng-c420556888="" className="row">
               
                <div _ngcontent-ng-c420556888="" className="col-md-3">
                  <div _ngcontent-ng-c420556888="" className="clearfix_input">
                    <label _ngcontent-ng-c420556888="" htmlFor="">
                      Session Commission Type
                    </label>
                    <input
                      _ngcontent-ng-c420556888=""
                      type="text"
                      placeholder={0}
                      disabled
                      value={
                        clientData?.parentSessionCommission
                          ?.matchCommission != "0" &&
                        clientData?.parentSessionCommission
                          ?.sessionCommission != "0"
                          ? "Bet By Bet"
                          : "No Comm"
                      }
                      name="SASessionCommissionType"
                      formcontrolname="parrent_sessionType"
                      id="SASessionCommissionType"
                      readOnly=""
                      className="form-control ng-untouched ng-pristine ng-valid"
                    />
                  </div>
                </div>
                <div _ngcontent-ng-c420556888="" className="col-md-3 col-6">
                  <div _ngcontent-ng-c420556888="" className="clearfix_input">
                    <label _ngcontent-ng-c420556888="" htmlFor="">
                      Match Commission
                    </label>
                    <input
                      _ngcontent-ng-c420556888=""
                      placeholder={0}
                      type="text"
                      id="name"
                      value={clientData?.parentMatchCommission}
                      name="name"
                      formcontrolname="parrent_matchCommission"
                      readOnly=""
                      disabled
                      className="form-control ng-untouched ng-pristine ng-valid"
                    />
                  </div>
                </div>
                <div _ngcontent-ng-c420556888="" className="col-md-3 col-6">
                  <div _ngcontent-ng-c420556888="" className="clearfix_input">
                    <label _ngcontent-ng-c420556888="" htmlFor="">
                      Session Commission
                    </label>
                    <input
                      _ngcontent-ng-c420556888=""
                      placeholder={0}
                      formcontrolname="parrent_sessionCommission"
                      type="text"
                      id="name"
                      name="name"
                      value={clientData?.parentSessionCommission}
                      disabled
                      readOnly=""
                      className="form-control ng-untouched ng-pristine ng-valid"
                    />
                  </div>
                </div>
              </div>
              <div
                style={{
                  borderTop: "2px solid #e9ecef",
                }}
                _ngcontent-ng-c420556888=""
                className="row"
              >
               

                <div _ngcontent-ng-c420556888="" className="col-md-3">
                  <div _ngcontent-ng-c420556888="" className="clearfix_input">
                    <label _ngcontent-ng-c420556888="" htmlFor="">
                      My Casino Commission
                    </label>
                    <select
                      _ngcontent-ng-c420556888=""
                      aria-label="Default select example"
                      value={isCasinoCommission}
                      onChange={(e) => setIsCasinoCommission(e.target.value)}
                      formcontrolname="session_commission_type"
                      className="form-select form-control ng-untouched ng-pristine ng-invalid"
                    >
                      <option
                        _ngcontent-ng-c420556888=""
                        value="false"
                        selected=""
                      >
                        Commission Type
                      </option>
                      <option value="false" _ngcontent-ng-c420556888="">
                        No Comm
                      </option>
                      <option value="yes" _ngcontent-ng-c420556888="">
                        Bet By Bet
                      </option>
                    </select>
                  </div>
                </div>
                {/* {userType != "client" && (
                  <>
                    <div
                      _ngcontent-ng-c420556888=""
                      className="col-md-3 col-6"
                    >
                      <div
                        _ngcontent-ng-c420556888=""
                        className="clearfix_input"
                      >
                        <label _ngcontent-ng-c420556888="" htmlFor="">
                          Casino Partnership{" "}
                        </label>
                        <input
                          type="number"
                          max={
                            clientData?.partnershipAndCommission
                              ?.myCasinoPartnership
                          }
                          min={0.0}
                          defaultValue={0}
                          id="casino_commission"
                          required=""
                          value={payload?.myCasinoPartnership}
                          name="casinoCommission"
                          onChange={(e) =>
                            setPayload({
                              ...payload,
                              myCasinoPartnership: e.target.value,
                            })
                          }
                          className="form-control disabled ng-untouched ng-pristine ng-invalid"
                        />
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c420556888=""
                      className="col-md-3 col-6"
                    >
                      <div
                        _ngcontent-ng-c420556888=""
                        className="clearfix_input"
                      >
                        <label _ngcontent-ng-c420556888="" htmlFor="">
                          My Casino Share
                        </label>
                        <input
                          type="number"
                          value={clientData?.parentCasinoPartnership}
                          readOnly
                          className="form-control disabled ng-untouched ng-pristine ng-invalid"
                        />
                      </div>
                    </div>
                  </>
                )}*/}
                {isCasinoCommission == "yes" && (
                  <>
                    <div
                      _ngcontent-ng-c420556888=""
                      className="col-md-3 col-6"
                    >
                      <div
                        _ngcontent-ng-c420556888=""
                        className="clearfix_input"
                      >
                        <label _ngcontent-ng-c420556888="" htmlFor="">
                          Casino Commission{" "}
                        </label>
                        <input
                          type="number"
                          max={
                            clientData?.partnershipAndCommission
                              ?.casinoCommission
                          }
                          min={0.0}
                          defaultValue={0}
                          id="casino_commission"
                          required=""
                          value={payload?.casinoCommission}
                          name="casinoCommission"
                          onChange={(e) =>
                            setPayload({
                              ...payload,
                              casinoCommission: e.target.value,
                            })
                          }
                          className="form-control disabled ng-untouched ng-pristine ng-invalid"
                        />
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c420556888=""
                      className="col-md-3 col-6"
                    >
                      <div
                        _ngcontent-ng-c420556888=""
                        className="clearfix_input"
                      >
                        <label _ngcontent-ng-c420556888="" htmlFor="">
                          My Casino Commission
                        </label>
                        <input
                          type="number"
                          value={clientData?.parentCasinoCommission}
                          readOnly
                          className="form-control disabled ng-untouched ng-pristine ng-invalid"
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div
                style={{
                  borderTop: "2px solid #e9ecef",
                }}
                _ngcontent-ng-c420556888=""
                className="row"
              >
              

                <div _ngcontent-ng-c420556888="" className="col-md-3">
                  <div _ngcontent-ng-c420556888="" className="clearfix_input">
                    <label _ngcontent-ng-c420556888="" htmlFor="">
                      My Matka Commission
                    </label>
                    <select
                      _ngcontent-ng-c420556888=""
                      aria-label="Default select example"
                      value={isMatkaCommission}
                      onChange={(e) => setIsMatkaCommission(e.target.value)}
                      formcontrolname="session_commission_type"
                      className="form-select form-control ng-untouched ng-pristine ng-invalid"
                    >
                      <option
                        _ngcontent-ng-c420556888=""
                        value="false"
                        selected=""
                      >
                        Commission Type
                      </option>
                      <option value="false" _ngcontent-ng-c420556888="">
                        No Comm
                      </option>
                      <option value="yes" _ngcontent-ng-c420556888="">
                        Bet By Bet
                      </option>
                    </select>
                  </div>
                </div>
                {/* {userType != "client" && (
                  <>
                    <div
                      _ngcontent-ng-c420556888=""
                      className="col-md-3 col-6"
                    >
                      <div
                        _ngcontent-ng-c420556888=""
                        className="clearfix_input"
                      >
                        <label _ngcontent-ng-c420556888="" htmlFor="">
                          Casino Partnership{" "}
                        </label>
                        <input
                          type="number"
                          max={
                            clientData?.partnershipAndCommission
                              ?.myCasinoPartnership
                          }
                          min={0.0}
                          defaultValue={0}
                          id="casino_commission"
                          required=""
                          value={payload?.myCasinoPartnership}
                          name="casinoCommission"
                          onChange={(e) =>
                            setPayload({
                              ...payload,
                              myCasinoPartnership: e.target.value,
                            })
                          }
                          className="form-control disabled ng-untouched ng-pristine ng-invalid"
                        />
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c420556888=""
                      className="col-md-3 col-6"
                    >
                      <div
                        _ngcontent-ng-c420556888=""
                        className="clearfix_input"
                      >
                        <label _ngcontent-ng-c420556888="" htmlFor="">
                          My Casino Share
                        </label>
                        <input
                          type="number"
                          value={clientData?.parentCasinoPartnership}
                          readOnly
                          className="form-control disabled ng-untouched ng-pristine ng-invalid"
                        />
                      </div>
                    </div>
                  </>
                )}*/}
                {isMatkaCommission == "yes" && (
                  <>
                    <div
                      _ngcontent-ng-c420556888=""
                      className="col-md-3 col-6"
                    >
                      <div
                        _ngcontent-ng-c420556888=""
                        className="clearfix_input"
                      >
                        <label _ngcontent-ng-c420556888="" htmlFor="">
                          Matka Commission{" "}
                        </label>
                        <input
                          type="number"
                          max={
                            clientData?.partnershipAndCommission
                              ?.matkaCommission
                          }
                          min={0.0}
                          defaultValue={0}
                          id="casino_commission"
                          required=""
                          value={payload?.matkaCommission}
                          name="casinoCommission"
                          onChange={(e) =>
                            setPayload({
                              ...payload,
                              matkaCommission: e.target.value,
                            })
                          }
                          className="form-control disabled ng-untouched ng-pristine ng-invalid"
                        />
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c420556888=""
                      className="col-md-3 col-6"
                    >
                      <div
                        _ngcontent-ng-c420556888=""
                        className="clearfix_input"
                      >
                        <label _ngcontent-ng-c420556888="" htmlFor="">
                          My Matka Commission
                        </label>
                        <input
                          type="number"
                          value={clientData?.parentMatkaCommission}
                          readOnly
                          className="form-control disabled ng-untouched ng-pristine ng-invalid"
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div
                style={{
                  borderTop: "2px solid #e9ecef",
                }}
                _ngcontent-ng-c420556888=""
                className="row"
              >
                <h6
                  _ngcontent-ng-c420556888=""
                  className="input-sub-headding"
                >
                  Casino Control
                </h6>

                <div _ngcontent-ng-c420556888="" className="col-md-3">
                  <div _ngcontent-ng-c420556888="" className="clearfix_input">
                    <label htmlFor="casinoCheck">Casino Play</label>
                    <br />
                    <label class="switch">
                      <input
                        value={!clientData?.casinoLocked}
                        defaultChecked={!clientData?.casinoLocked}
                        checked={!clientData?.casinoLocked}
                        onChange={handleCasinoChange}
                        type="checkbox"
                      />
                      <span class="slider round" />
                    </label>
                  </div>
                </div>
              </div>
              <div
                style={{
                  borderTop: "2px solid #e9ecef",
                }}
                _ngcontent-ng-c420556888=""
                className="row"
              >
                <h6
                  _ngcontent-ng-c420556888=""
                  className="input-sub-headding"
                >
                 Matka Control
                </h6>

                <div _ngcontent-ng-c420556888="" className="col-md-3">
                  <div _ngcontent-ng-c420556888="" className="clearfix_input">
                    <label htmlFor="casinoCheck">Matka Play</label>
                    <br />
                    <label class="switch">
                      <input
                        value={!clientData?.matkaLocked}
                        defaultChecked={!clientData?.matkaLocked}
                        checked={!clientData?.matkaLocked}
                        onChange={(e) => handleCasinoChange(e, true)}
                        type="checkbox"
                      />
                      <span class="slider round" />
                    </label>
                  </div>
                </div>
              </div>
              <div _ngcontent-ng-c420556888="" className="jmd_input_btn">
                <button
                  _ngcontent-ng-c420556888=""
                  type="button"
                  className="btn btn-primary"
                  onClick={handleSubmit}
                >
                  Save
                </button>
                <button
                  _ngcontent-ng-c420556888=""
                  type="button"
                  onClick={() => {
                    window.location.href = `/${userType}`;
                  }}
                  className="btn btn-secondary"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
        <div
          _ngcontent-ng-c420556888=""
          id="detailaModal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
          className="sitemoadal modal fade"
        >
          <div
            _ngcontent-ng-c420556888=""
            className="modal-dialog modal-dialog-centered"
          >
            <div _ngcontent-ng-c420556888="" className="modal-content">
              <div _ngcontent-ng-c420556888="" className="modal-header">
                <h5
                  _ngcontent-ng-c420556888=""
                  id="exampleModalLabel"
                  className="modal-title"
                >
                  User Deatils
                </h5>
                <button
                  _ngcontent-ng-c420556888=""
                  type="button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  className="btn-close"
                />
              </div>
              <div _ngcontent-ng-c420556888="" className="modal-body">
                <div _ngcontent-ng-c420556888="" className="bet-slip-box">
                  <div _ngcontent-ng-c420556888="" className="bet-slip">
                    <div _ngcontent-ng-c420556888="" className="bet-nation">
                      <span _ngcontent-ng-c420556888="">UserName : </span>
                    </div>
                    <div
                      _ngcontent-ng-c420556888=""
                      className="match-result"
                    />
                  </div>
                  <div _ngcontent-ng-c420556888="" className="bet-slip">
                    <div _ngcontent-ng-c420556888="" className="bet-nation">
                      <span _ngcontent-ng-c420556888="">password :</span>
                    </div>
                    <div
                      _ngcontent-ng-c420556888=""
                      className="match-result"
                    />
                  </div>
                  <div
                    _ngcontent-ng-c420556888=""
                    className="place-bet-btn mt-4 text-align"
                  >
                    <button
                      _ngcontent-ng-c420556888=""
                      className="btn btn-primary btn-block"
                    >
                      <span _ngcontent-ng-c420556888="">
                        Share on WhatsApp
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </app-create-user>
      {/**/}
    </div>
  );
}
