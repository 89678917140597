import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { getPlusMinusReportAPI } from "../service/AuthService";
import { PERMISSION_MAP } from "../utils/constants";
import { useSelector } from "react-redux";
const colorMap = [
  "dark",
  "primary",
  "secondary",
  "info",
  "success",
  "warning",
  "danger",
  "indigo",
  "light-blue",
];
let tableData = [];
export default function MatchSessionPlusMinusDisplayCasino({ isCasino }) {
  const params = useParams();
  const userData = useSelector((state) => state.account.userData);
  const { id } = params;
  const location = useLocation();
  const [data, setData] = React.useState([]);

  const getData = async () => {
    const { response } = await getPlusMinusReportAPI(id, {
      marketIds: location?.state?.selected,
      userIds: location?.state?.selectedUsers,
      isBoth: true,
      isCasino,
    });
    setData(buildTree(response));
  };

  const buildTree = (node) => {
    const tree = {
      name: node.name,
      children: [],
      total: node.total,
      tableData: [],
      color: colorMap[Math.floor(Math.random() * colorMap.length)],
    };

    if (node.plusMinus && node?.plusMinus.length > 0) {
      if (node?.plusMinus?.length > 0) {
        node.plusMinus.forEach((childNode) => {
          tree.children.push(buildTree(childNode));
          if (!childNode.total) {
            tree.tableData.push(childNode);
            tableData.push(childNode);
          }
        });
      }
    }
    return tree;
  };

  useEffect(() => {
    getData();
  }, [0]);
  return (
    <div
      className="content-wrapper"
      style={{
        minHeight: "1300.41px",
      }}
    >
      {/* Content Header (Page header) */}
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Match &amp; Session Plus Minus Report :{id}</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
                <li className="breadcrumb-item active">
                  Match &amp; Session Plus Minus Report
                </li>
              </ol>
            </div>
          </div>
        </div>
        {/* /.container-fluid */}
      </section>
      <section className="content">
        <div className="row">
          <div className="card card-default">
            <div className="card-header text-center text-bold">
              <h5 className="card-title">
                MATCH CODE : ({id}) {userData?.userType} &{" "}
                {PERMISSION_MAP[(userData?.userType)].map(
                  (item) => item + " & "
                )}{" "}
                OF {location?.state?.match?.eventName}
              </h5>
            </div>
            <div className="card-body">
              <Tree tree={data} index={0} />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
function TreeNode({ node, index }) {
  return (
    <div
      style={{ width: "min-content" }}
      className={`card card-${node.color} bg-light`}
    >
      <div className="card-header text-center">
        <h5 className="card-title">{node.name}</h5>
      </div>
      <div className="card-body">
        {node.children &&
          node.children.map((child) => {
            return (
              child?.name &&
              child?.total &&
              Object.keys(child?.total?.userTypePlusMinusBaseDTOMap).length >
                0 &&
              child?.children.length > 0 && (
                <TreeNode key={child.name} node={child} />
              )
            );
          })}
        {node.tableData?.length > 0 && (
          <table id="data" className="table table-striped table-bordered">
            <thead>
              <tr>
                <th colspan={"10"} class="text-center">
                  Agent PlusMinus
                </th>
                {node?.tableData[0]?.userTypePlusMinusBaseDTOMap
                  ?.SUPER_AGENT && (
                  <th colspan="6" class="text-center">
                    Super Agent PlusMinus
                  </th>
                )}
                {node?.tableData[0]?.userTypePlusMinusBaseDTOMap?.MASTER && (
                  <th colspan="6" class="text-center">
                    Master Agent PlusMinus
                  </th>
                )}
                {node?.tableData[0]?.userTypePlusMinusBaseDTOMap?.SUB_ADMIN && (
                  <th colspan="6" class="text-center">
                    Sub Admin PlusMinus
                  </th>
                )}

                {node?.tableData[0]?.userTypePlusMinusBaseDTOMap?.ADMIN && (
                  <th colspan="6" class="text-center">
                    Admin
                  </th>
                )}

                {node?.tableData[0]?.userTypePlusMinusBaseDTOMap
                  .SUPER_ADMIN && (
                  <th colspan="6" class="text-center">
                    Super Admin
                  </th>
                )}
              </tr>
            </thead>
            <thead>
              <tr>
                <th>CLIENT</th>
                <th>Casino AMT</th>
                <th>S AMT</th>
                <th>TOT AMT</th>
                <th>M COM</th>
                <th>S COM</th>
                <th>TOT COM</th>
                <th>NET AMT</th>
                <th>SHR AMT</th>
                <th>FINAL</th>
                {node?.tableData[0]?.userTypePlusMinusBaseDTOMap
                  .SUPER_AGENT && (
                  <>
                    <th>M COM</th>
                    <th>S COM</th>
                    <th>TOL COM</th>
                    <th>NET AMT</th>
                    <th>SHR AMT</th>
                    <th>FINAL</th>
                  </>
                )}
                {node?.tableData[0]?.userTypePlusMinusBaseDTOMap?.MASTER && (
                  <>
                    <th>M COM</th>
                    <th>S COM</th>
                    <th>TOL COM</th>
                    <th>NET AMT</th>
                    <th>SHR AMT</th>
                    <th>FINAL</th>
                  </>
                )}
                {node?.tableData[0]?.userTypePlusMinusBaseDTOMap?.SUB_ADMIN && (
                  <>
                    <th>M COM</th>
                    <th>S COM</th>
                    <th>TOL COM</th>
                    <th>NET AMT</th>
                    <th>SHR AMT</th>
                    <th>FINAL</th>
                  </>
                )}
                {node?.tableData[0]?.userTypePlusMinusBaseDTOMap?.ADMIN && (
                  <>
                    <th>M COM</th>
                    <th>S COM</th>
                    <th>TOL COM</th>
                    <th>NET AMT</th>
                    <th>SHR AMT</th>
                    <th>FINAL</th>
                  </>
                )}
                {node?.tableData[0]?.userTypePlusMinusBaseDTOMap
                  ?.SUPER_ADMIN && (
                  <>
                    <th>M COM</th>
                    <th>S COM</th>
                    <th>TOL COM</th>
                    <th>NET AMT</th>
                    <th>SHR AMT</th>
                    <th>FINAL</th>
                  </>
                )}
              </tr>
            </thead>
            <tbody>
              {node.tableData?.map((item) => (
                <tr>
                  <td>{item?.clientUsernameAndName}</td>
                  <td>{parseFloat(item?.matchAmount).toFixed(2)}</td>
                  <td>{parseFloat(item?.sessionAmount).toFixed(2)}</td>
                  <td>{parseFloat(item?.totalAmount).toFixed(2)}</td>
                  <td>
                    {parseFloat(
                      item?.userTypePlusMinusBaseDTOMap?.AGENT?.matchCommission
                    ).toFixed(2)}
                  </td>
                  <td>
                    {parseFloat(
                      item?.userTypePlusMinusBaseDTOMap?.AGENT
                        ?.sessionCommission
                    ).toFixed(2)}
                  </td>
                  <td>
                    {parseFloat(
                      item?.userTypePlusMinusBaseDTOMap?.AGENT?.totalCommission
                    ).toFixed(2)}
                  </td>
                  <td>
                    {parseFloat(
                      item?.userTypePlusMinusBaseDTOMap?.AGENT?.netAmount
                    ).toFixed(2)}
                  </td>
                  <td>
                    {parseFloat(
                      item?.userTypePlusMinusBaseDTOMap?.AGENT?.shareAmount
                    ).toFixed(2)}
                  </td>
                  <td>
                    {parseFloat(
                      item?.userTypePlusMinusBaseDTOMap?.AGENT?.finalAmount
                    ).toFixed(2)}
                  </td>
                  {item?.userTypePlusMinusBaseDTOMap?.SUPER_AGENT && (
                    <>
                      <td>
                        {parseFloat(
                          item?.userTypePlusMinusBaseDTOMap?.SUPER_AGENT
                            ?.matchCommission
                        ).toFixed(2)}
                      </td>
                      <td>
                        {parseFloat(
                          item?.userTypePlusMinusBaseDTOMap?.SUPER_AGENT
                            ?.sessionCommission
                        ).toFixed(2)}
                      </td>
                      <td>
                        {parseFloat(
                          item?.userTypePlusMinusBaseDTOMap?.SUPER_AGENT
                            ?.totalCommission
                        ).toFixed(2)}
                      </td>
                      <td>
                        {parseFloat(
                          item?.userTypePlusMinusBaseDTOMap?.SUPER_AGENT
                            ?.netAmount
                        ).toFixed(2)}
                      </td>
                      <td>
                        {parseFloat(
                          item?.userTypePlusMinusBaseDTOMap?.SUPER_AGENT
                            ?.shareAmount
                        ).toFixed(2)}
                      </td>
                      <td>
                        {parseFloat(
                          item?.userTypePlusMinusBaseDTOMap?.SUPER_AGENT
                            ?.finalAmount
                        ).toFixed(2)}
                      </td>
                    </>
                  )}
                  {item?.userTypePlusMinusBaseDTOMap?.MASTER && (
                    <>
                      <td>
                        {parseFloat(
                          item?.userTypePlusMinusBaseDTOMap?.MASTER
                            ?.matchCommission
                        ).toFixed(2)}
                      </td>
                      <td>
                        {parseFloat(
                          item?.userTypePlusMinusBaseDTOMap?.MASTER
                            ?.sessionCommission
                        ).toFixed(2)}
                      </td>
                      <td>
                        {parseFloat(
                          item?.userTypePlusMinusBaseDTOMap?.MASTER
                            ?.totalCommission
                        ).toFixed(2)}
                      </td>
                      <td>
                        {parseFloat(
                          item?.userTypePlusMinusBaseDTOMap?.MASTER?.netAmount
                        ).toFixed(2)}
                      </td>
                      <td>
                        {parseFloat(
                          item?.userTypePlusMinusBaseDTOMap?.MASTER?.shareAmount
                        ).toFixed(2)}
                      </td>
                      <td>
                        {parseFloat(
                          item?.userTypePlusMinusBaseDTOMap?.MASTER?.finalAmount
                        ).toFixed(2)}
                      </td>
                    </>
                  )}
                  {item?.userTypePlusMinusBaseDTOMap?.SUB_ADMIN && (
                    <>
                      <td>
                        {parseFloat(
                          item?.userTypePlusMinusBaseDTOMap?.SUB_ADMIN
                            ?.matchCommission
                        ).toFixed(2)}
                      </td>
                      <td>
                        {parseFloat(
                          item?.userTypePlusMinusBaseDTOMap?.SUB_ADMIN
                            ?.sessionCommission
                        ).toFixed(2)}
                      </td>
                      <td>
                        {parseFloat(
                          item?.userTypePlusMinusBaseDTOMap?.SUB_ADMIN
                            ?.totalCommission
                        ).toFixed(2)}
                      </td>
                      <td>
                        {parseFloat(
                          item?.userTypePlusMinusBaseDTOMap?.SUB_ADMIN
                            ?.netAmount
                        ).toFixed(2)}
                      </td>
                      <td>
                        {parseFloat(
                          item?.userTypePlusMinusBaseDTOMap?.SUB_ADMIN
                            ?.shareAmount
                        ).toFixed(2)}
                      </td>
                      <td>
                        {parseFloat(
                          item?.userTypePlusMinusBaseDTOMap?.SUB_ADMIN
                            ?.finalAmount
                        ).toFixed(2)}
                      </td>
                    </>
                  )}
                  {item?.userTypePlusMinusBaseDTOMap?.ADMIN && (
                    <>
                      <td>
                        {parseFloat(
                          item?.userTypePlusMinusBaseDTOMap?.ADMIN
                            ?.matchCommission
                        ).toFixed(2)}
                      </td>
                      <td>
                        {parseFloat(
                          item?.userTypePlusMinusBaseDTOMap?.ADMIN
                            ?.sessionCommission
                        ).toFixed(2)}
                      </td>
                      <td>
                        {parseFloat(
                          item?.userTypePlusMinusBaseDTOMap?.ADMIN
                            ?.totalCommission
                        ).toFixed(2)}
                      </td>
                      <td>
                        {parseFloat(
                          item?.userTypePlusMinusBaseDTOMap?.ADMIN?.netAmount
                        ).toFixed(2)}
                      </td>
                      <td>
                        {parseFloat(
                          item?.userTypePlusMinusBaseDTOMap?.ADMIN?.shareAmount
                        ).toFixed(2)}
                      </td>
                      <td>
                        {parseFloat(
                          item?.userTypePlusMinusBaseDTOMap?.ADMIN?.finalAmount
                        ).toFixed(2)}
                      </td>
                    </>
                  )}
                  {item?.userTypePlusMinusBaseDTOMap?.SUPER_ADMIN && (
                    <>
                      <td>
                        {parseFloat(
                          item?.userTypePlusMinusBaseDTOMap?.SUPER_ADMIN
                            ?.matchCommission
                        ).toFixed(2)}
                      </td>
                      <td>
                        {parseFloat(
                          item?.userTypePlusMinusBaseDTOMap?.SUPER_ADMIN
                            ?.sessionCommission
                        ).toFixed(2)}
                      </td>
                      <td>
                        {parseFloat(
                          item?.userTypePlusMinusBaseDTOMap?.SUPER_ADMIN
                            ?.totalCommission
                        ).toFixed(2)}
                      </td>
                      <td>
                        {parseFloat(
                          item?.userTypePlusMinusBaseDTOMap?.SUPER_ADMIN
                            ?.netAmount
                        ).toFixed(2)}
                      </td>
                      <td>
                        {parseFloat(
                          item?.userTypePlusMinusBaseDTOMap?.SUPER_ADMIN
                            ?.shareAmount
                        ).toFixed(2)}
                      </td>
                      <td>
                        {parseFloat(
                          item?.userTypePlusMinusBaseDTOMap?.SUPER_ADMIN
                            ?.finalAmount
                        ).toFixed(2)}
                      </td>
                    </>
                  )}
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <th>{node?.total?.clientUsernameAndName}</th>

                <th>{parseFloat(node?.total?.matchAmount).toFixed(2)}</th>
                <th>{parseFloat(node?.total?.sessionAmount).toFixed(2)}</th>
                <th>{parseFloat(node?.total?.totalAmount).toFixed(2)}</th>
                <th>
                  {parseFloat(
                    node?.total?.userTypePlusMinusBaseDTOMap?.AGENT
                      ?.matchCommission
                  ).toFixed(2)}
                </th>

                <th>
                  {parseFloat(
                    node?.total?.userTypePlusMinusBaseDTOMap?.AGENT
                      ?.sessionCommission
                  ).toFixed(2)}
                </th>
                <th>
                  {parseFloat(
                    node?.total?.userTypePlusMinusBaseDTOMap?.AGENT
                      ?.totalCommission
                  ).toFixed(2)}
                </th>
                <th>
                  {parseFloat(
                    node?.total?.userTypePlusMinusBaseDTOMap?.AGENT?.netAmount
                  ).toFixed(2)}
                </th>
                <th>
                  {parseFloat(
                    node?.total?.userTypePlusMinusBaseDTOMap?.AGENT?.shareAmount
                  ).toFixed(2)}
                </th>
                <th>
                  {parseFloat(
                    node?.total?.userTypePlusMinusBaseDTOMap?.AGENT?.finalAmount
                  ).toFixed(2)}
                </th>
                {node?.total?.userTypePlusMinusBaseDTOMap?.SUPER_AGENT && (
                  <>
                    <th>
                      {parseFloat(
                        node?.total?.userTypePlusMinusBaseDTOMap?.SUPER_AGENT
                          ?.matchCommission
                      ).toFixed(2)}
                    </th>
                    <th>
                      {parseFloat(
                        node?.total?.userTypePlusMinusBaseDTOMap?.SUPER_AGENT
                          ?.sessionCommission
                      ).toFixed(2)}
                    </th>
                    <th>
                      {parseFloat(
                        node?.total?.userTypePlusMinusBaseDTOMap?.SUPER_AGENT
                          ?.totalCommission
                      ).toFixed(2)}
                    </th>
                    <th>
                      {parseFloat(
                        node?.total?.userTypePlusMinusBaseDTOMap?.SUPER_AGENT
                          ?.netAmount
                      ).toFixed(2)}
                    </th>
                    <th>
                      {parseFloat(
                        node?.total?.userTypePlusMinusBaseDTOMap?.SUPER_AGENT
                          ?.shareAmount
                      ).toFixed(2)}
                    </th>
                    <th>
                      {parseFloat(
                        node?.total?.userTypePlusMinusBaseDTOMap?.SUPER_AGENT
                          ?.finalAmount
                      ).toFixed(2)}
                    </th>
                  </>
                )}
                {node?.total?.userTypePlusMinusBaseDTOMap?.MASTER && (
                  <>
                    <th>
                      {parseFloat(
                        node?.total?.userTypePlusMinusBaseDTOMap?.MASTER
                          ?.matchCommission
                      ).toFixed(2)}
                    </th>
                    <th>
                      {parseFloat(
                        node?.total?.userTypePlusMinusBaseDTOMap?.MASTER
                          ?.sessionCommission
                      ).toFixed(2)}
                    </th>
                    <th>
                      {parseFloat(
                        node?.total?.userTypePlusMinusBaseDTOMap?.MASTER
                          ?.totalCommission
                      ).toFixed(2)}
                    </th>
                    <th>
                      {parseFloat(
                        node?.total?.userTypePlusMinusBaseDTOMap?.MASTER
                          ?.netAmount
                      ).toFixed(2)}
                    </th>
                    <th>
                      {parseFloat(
                        node?.total?.userTypePlusMinusBaseDTOMap?.MASTER
                          ?.shareAmount
                      ).toFixed(2)}
                    </th>
                    <th>
                      {parseFloat(
                        node?.total?.userTypePlusMinusBaseDTOMap?.MASTER
                          ?.finalAmount
                      ).toFixed(2)}
                    </th>
                  </>
                )}
                {node?.total?.userTypePlusMinusBaseDTOMap?.SUB_ADMIN && (
                  <>
                    <th>
                      {parseFloat(
                        node?.total?.userTypePlusMinusBaseDTOMap?.SUB_ADMIN
                          ?.matchCommission
                      ).toFixed(2)}
                    </th>
                    <th>
                      {parseFloat(
                        node?.total?.userTypePlusMinusBaseDTOMap?.SUB_ADMIN
                          ?.sessionCommission
                      ).toFixed(2)}
                    </th>
                    <th>
                      {parseFloat(
                        node?.total?.userTypePlusMinusBaseDTOMap?.SUB_ADMIN
                          ?.totalCommission
                      ).toFixed(2)}
                    </th>
                    <th>
                      {parseFloat(
                        node?.total?.userTypePlusMinusBaseDTOMap?.SUB_ADMIN
                          ?.netAmount
                      ).toFixed(2)}
                    </th>
                    <th>
                      {parseFloat(
                        node?.total?.userTypePlusMinusBaseDTOMap?.SUB_ADMIN
                          ?.shareAmount
                      ).toFixed(2)}
                    </th>
                    <th>
                      {parseFloat(
                        node?.total?.userTypePlusMinusBaseDTOMap?.SUB_ADMIN
                          ?.finalAmount
                      ).toFixed(2)}
                    </th>
                  </>
                )}
                {node?.total?.userTypePlusMinusBaseDTOMap?.ADMIN && (
                  <>
                    <th>
                      {parseFloat(
                        node?.total?.userTypePlusMinusBaseDTOMap?.ADMIN
                          ?.matchCommission
                      ).toFixed(2)}
                    </th>
                    <th>
                      {parseFloat(
                        node?.total?.userTypePlusMinusBaseDTOMap?.ADMIN
                          ?.sessionCommission
                      ).toFixed(2)}
                    </th>
                    <th>
                      {parseFloat(
                        node?.total?.userTypePlusMinusBaseDTOMap?.ADMIN
                          ?.totalCommission
                      ).toFixed(2)}
                    </th>
                    <th>
                      {parseFloat(
                        node?.total?.userTypePlusMinusBaseDTOMap?.ADMIN
                          ?.netAmount
                      ).toFixed(2)}
                    </th>
                    <th>
                      {parseFloat(
                        node?.total?.userTypePlusMinusBaseDTOMap?.ADMIN
                          ?.shareAmount
                      ).toFixed(2)}
                    </th>
                    <th>
                      {parseFloat(
                        node?.total?.userTypePlusMinusBaseDTOMap?.ADMIN
                          ?.finalAmount
                      ).toFixed(2)}
                    </th>
                  </>
                )}
                {node?.total?.userTypePlusMinusBaseDTOMap?.SUPER_ADMIN && (
                  <>
                    <th>
                      {parseFloat(
                        node?.total?.userTypePlusMinusBaseDTOMap?.SUPER_ADMIN
                          ?.matchCommission
                      ).toFixed(2)}
                    </th>
                    <th>
                      {parseFloat(
                        node?.total?.userTypePlusMinusBaseDTOMap?.SUPER_ADMIN
                          ?.sessionCommission
                      ).toFixed(2)}
                    </th>
                    <th>
                      {parseFloat(
                        node?.total?.userTypePlusMinusBaseDTOMap?.SUPER_ADMIN
                          ?.totalCommission
                      ).toFixed(2)}
                    </th>
                    <th>
                      {parseFloat(
                        node?.total?.userTypePlusMinusBaseDTOMap?.SUPER_ADMIN
                          ?.netAmount
                      ).toFixed(2)}
                    </th>
                    <th>
                      {parseFloat(
                        node?.total?.userTypePlusMinusBaseDTOMap?.SUPER_ADMIN
                          ?.shareAmount
                      ).toFixed(2)}
                    </th>
                    <th>
                      {parseFloat(
                        node?.total?.userTypePlusMinusBaseDTOMap?.SUPER_ADMIN
                          ?.finalAmount
                      ).toFixed(2)}
                    </th>
                  </>
                )}
              </tr>
            </tfoot>
          </table>
        )}
      </div>
      {node?.tableData?.length == 0 && node.children.length > 0 && (
        <div className="card-footer">
          <table className="table table-striped table-bordered">
            <thead className="bg-gradient-white">
              <tr>
                <th>{node?.total?.clientUsernameAndName}</th>
                <th>{parseFloat(node?.total?.matchAmount).toFixed(2)}</th>
                <th>{parseFloat(node?.total?.sessionAmount).toFixed(2)}</th>
                <th>{parseFloat(node?.total?.totalAmount).toFixed(2)}</th>
                <th>
                  {parseFloat(
                    node?.total?.userTypePlusMinusBaseDTOMap?.AGENT
                      ?.matchCommission
                  ).toFixed(2)}
                </th>
                <th>
                  {parseFloat(
                    node?.total?.userTypePlusMinusBaseDTOMap?.AGENT
                      ?.sessionCommission
                  ).toFixed(2)}
                </th>
                <th>
                  {parseFloat(
                    node?.total?.userTypePlusMinusBaseDTOMap?.AGENT
                      ?.totalCommission
                  ).toFixed(2)}
                </th>
                <th>
                  {parseFloat(
                    node?.total?.userTypePlusMinusBaseDTOMap?.AGENT?.netAmount
                  ).toFixed(2)}
                </th>
                <th>
                  {parseFloat(
                    node?.total?.userTypePlusMinusBaseDTOMap?.AGENT?.shareAmount
                  ).toFixed(2)}
                </th>
                <th>
                  {parseFloat(
                    node?.total?.userTypePlusMinusBaseDTOMap?.AGENT?.finalAmount
                  ).toFixed(2)}
                </th>
                <th>
                  {parseFloat(
                    node?.total?.userTypePlusMinusBaseDTOMap?.SUPER_AGENT
                      ?.matchCommission
                  ).toFixed(2)}
                </th>
                <th>
                  {parseFloat(
                    node?.total?.userTypePlusMinusBaseDTOMap?.SUPER_AGENT
                      ?.sessionCommission
                  ).toFixed(2)}
                </th>
                <th>
                  {parseFloat(
                    node?.total?.userTypePlusMinusBaseDTOMap?.SUPER_AGENT
                      ?.totalCommission
                  ).toFixed(2)}
                </th>
                <th>
                  {parseFloat(
                    node?.total?.userTypePlusMinusBaseDTOMap?.SUPER_AGENT
                      ?.netAmount
                  ).toFixed(2)}
                </th>
                <th>
                  {parseFloat(
                    node?.total?.userTypePlusMinusBaseDTOMap?.SUPER_AGENT
                      ?.shareAmount
                  ).toFixed(2)}
                </th>
                <th>
                  {parseFloat(
                    node?.total?.userTypePlusMinusBaseDTOMap?.SUPER_AGENT
                      ?.finalAmount
                  ).toFixed(2)}
                </th>
                <th>
                  {parseFloat(
                    node?.total?.userTypePlusMinusBaseDTOMap?.MASTER
                      ?.matchCommission
                  ).toFixed(2)}
                </th>
                <th>
                  {parseFloat(
                    node?.total?.userTypePlusMinusBaseDTOMap?.MASTER
                      ?.sessionCommission
                  ).toFixed(2)}
                </th>
                <th>
                  {parseFloat(
                    node?.total?.userTypePlusMinusBaseDTOMap?.MASTER
                      ?.totalCommission
                  ).toFixed(2)}
                </th>
                <th>
                  {parseFloat(
                    node?.total?.userTypePlusMinusBaseDTOMap?.MASTER?.netAmount
                  ).toFixed(2)}
                </th>
                <th>
                  {parseFloat(
                    node?.total?.userTypePlusMinusBaseDTOMap?.MASTER
                      ?.shareAmount
                  ).toFixed(2)}
                </th>
                <th>
                  {parseFloat(
                    node?.total?.userTypePlusMinusBaseDTOMap?.MASTER
                      ?.finalAmount
                  ).toFixed(2)}
                </th>
                <th>
                  {parseFloat(
                    node?.total?.userTypePlusMinusBaseDTOMap?.SUB_ADMIN
                      ?.matchCommission
                  ).toFixed(2)}
                </th>
                <th>
                  {parseFloat(
                    node?.total?.userTypePlusMinusBaseDTOMap?.SUB_ADMIN
                      ?.sessionCommission
                  ).toFixed(2)}
                </th>
                <th>
                  {parseFloat(
                    node?.total?.userTypePlusMinusBaseDTOMap?.SUB_ADMIN
                      ?.totalCommission
                  ).toFixed(2)}
                </th>
                <th>
                  {parseFloat(
                    node?.total?.userTypePlusMinusBaseDTOMap?.SUB_ADMIN
                      ?.netAmount
                  ).toFixed(2)}
                </th>
                <th>
                  {parseFloat(
                    node?.total?.userTypePlusMinusBaseDTOMap?.SUB_ADMIN
                      ?.shareAmount
                  ).toFixed(2)}
                </th>
                <th>
                  {parseFloat(
                    node?.total?.userTypePlusMinusBaseDTOMap?.SUB_ADMIN
                      ?.finalAmount
                  ).toFixed(2)}
                </th>
                <th>
                  {parseFloat(
                    node?.total?.userTypePlusMinusBaseDTOMap?.ADMIN
                      ?.matchCommission
                  ).toFixed(2)}
                </th>
                <th>
                  {parseFloat(
                    node?.total?.userTypePlusMinusBaseDTOMap?.ADMIN
                      ?.sessionCommission
                  ).toFixed(2)}
                </th>
                <th>
                  {parseFloat(
                    node?.total?.userTypePlusMinusBaseDTOMap?.ADMIN
                      ?.totalCommission
                  ).toFixed(2)}
                </th>
                <th>
                  {parseFloat(
                    node?.total?.userTypePlusMinusBaseDTOMap?.ADMIN?.netAmount
                  ).toFixed(2)}
                </th>
                <th>
                  {parseFloat(
                    node?.total?.userTypePlusMinusBaseDTOMap?.ADMIN?.shareAmount
                  ).toFixed(2)}
                </th>
                <th>
                  {parseFloat(
                    node?.total?.userTypePlusMinusBaseDTOMap?.ADMIN?.finalAmount
                  ).toFixed(2)}
                </th>
                <th>
                  {parseFloat(
                    node?.total?.userTypePlusMinusBaseDTOMap?.SUPER_ADMIN
                      ?.matchCommission
                  ).toFixed(2)}
                </th>
                <th>
                  {parseFloat(
                    node?.total?.userTypePlusMinusBaseDTOMap?.SUPER_ADMIN
                      ?.sessionCommission
                  ).toFixed(2)}
                </th>
                <th>
                  {parseFloat(
                    node?.total?.userTypePlusMinusBaseDTOMap?.SUPER_ADMIN
                      ?.totalCommission
                  ).toFixed(2)}
                </th>
                <th>
                  {parseFloat(
                    node?.total?.userTypePlusMinusBaseDTOMap?.SUPER_ADMIN
                      ?.netAmount
                  ).toFixed(2)}
                </th>
                <th>
                  {parseFloat(
                    node?.total?.userTypePlusMinusBaseDTOMap?.SUPER_ADMIN
                      ?.shareAmount
                  ).toFixed(2)}
                </th>
                <th>
                  {parseFloat(
                    node?.total?.userTypePlusMinusBaseDTOMap?.SUPER_ADMIN
                      ?.finalAmount
                  ).toFixed(2)}
                </th>
              </tr>
            </thead>
          </table>
        </div>
      )}
    </div>
  );
}

function Tree({ tree, index }) {
  return (
    <div>
      <TreeNode node={tree} index={index} />
    </div>
  );
}
