import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getMatkaPlAPI } from "../../service/matka/matka.service";
import toast from "react-hot-toast";

export default function MatkaPosition() {
  const [matches, setMatches] = useState({});
  const location = useLocation();
  const getPl = async () => {
    const { response, code } = await getMatkaPlAPI(location?.state?.match?.id);
    if (code == 200) {
      const markets = response?.matkaPLExposure;
      const data = {};
      markets?.forEach((market) => {
        if (!data[(market?.betType)]) {
          data[(market?.betType)] = [
            {
              betNumber: market?.betNumber,
              exposure: market?.exposure,
            },
          ];
        } else {
          data[(market?.betType)].push({
            betNumber: market?.betNumber,
            exposure: market?.exposure,
          });
        }
      });
      setMatches(data);
    } else {
      toast.error(response);
    }
  };
  useEffect(() => {
    getPl();
    setInterval(() => {
      getPl();
    }, 5000);
  }, []);

  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">Matka Position</h1>
            </div>
          </div>
        </div>
      </div>
      <section className="content">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header text-center">
                <div className="container">
                  <div className="row">
                    <div className="col">
                      <h5 className="text-bold">
                        {location?.state?.match?.matka?.name}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="form-row">
                  {Object.keys(matches).length > 0 &&
                    Object.keys(matches)?.map((item) => (
                      <div className="col-md-4">
                        <table
                          align="left"
                          id="sessionsTable"
                          className="table table-striped  table-hover table-bordered"
                        >
                          <thead>
                            <tr>
                              <th>{item}</th>
                              <th>PROFIT/LOSS</th>
                            </tr>
                          </thead>
                          <tbody>
                            {Object.keys(matches).length > 0 &&
                              matches[item]?.map((pl) => (
                                <tr>
                                  <td>{pl?.betNumber}</td>
                                  <td
                                    style={{
                                      color: pl?.exposure < 0 ? "red" : "green",
                                    }}
                                  >
                                    {pl?.exposure}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
